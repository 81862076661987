import DetailNavTitle from '@businessComponents/detail-nav-title';
import {FadeInView} from '@basicComponents/animations';
import {goBack} from '@/utils';
import React, {useRef, useState, useEffect} from 'react';
import {useInnerStyle} from './promotion.hooks';
import theme from '@style';
import {FlatList, ListRenderItemInfo, RefreshControl, View} from 'react-native';
import Card from '@basicComponents/card';
import Text from '@basicComponents/text';
import Button from '@/components/basic/button';
// import Wallet from './common-pages/wallet/wallet-page';
import {NoMoreData} from '@/components/basic/default-page';
import {PromotionListItem, getPromotionList} from './promotion.service';
import globalStore from '@/services/global.state';
import NoData from '@/components/basic/error-pages/no-data';
import {goTo, goWhatsAppChat} from '@/utils';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {goToUrl} from '@/common-pages/game-navigate';
import {packageId} from '@/config';

// const signInImg = require('@assets/imgs/promotion-sign-in.webp');

const Promotion = () => {
  const {i18n} = useTranslation();
  const {
    size: {
      screenHeight,
      // itemImgWidth,
      // itemImgHeight,
      // signImgHeight,
      screenWidth,
      designWidth,
    },
    // itemStyle,
    listStyle,
  } = useInnerStyle();
  const [refreshing, setRefreshing] = useState(false);

  const pageNo = useRef(1);
  const totalPage = useRef(1);

  const [promotionList, setPromotionList] = useState<PromotionListItem[]>([
    // signInPromotion,
  ]);
  const refreshPageInfo = (isMore = false) => {
    return getPromotionList(pageNo.current).then(pageInfo => {
      if (pageInfo?.content) {
        setPromotionList(
          isMore
            ? [...promotionList, ...pageInfo.content]
            : [...pageInfo.content],
        );
        totalPage.current = pageInfo.totalPages;
      }
    });
  };
  const refreshPage = (loading = true, isMore = false) => {
    loading && globalStore.globalLoading.next(true);
    pageNo.current = 1;
    refreshPageInfo(isMore).finally(() => {
      globalStore.globalLoading.next(false);
      setRefreshing(false);
    });
  };
  const refreshNextPage = () => {
    if (pageNo.current < totalPage.current) {
      pageNo.current++;
      globalStore.globalLoading.next(true);
      refreshPageInfo(true).finally(() =>
        globalStore.globalLoading.next(false),
      );
    }
  };
  useEffect(() => {
    refreshPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const goPromotion = (url: string = '', title: string = '') => {
    if (url.startsWith('http')) {
      goToUrl(url, title);
    } else if (url.startsWith('gameType')) {
      const game = url.split('=')[1];
      // ToDo 无默认值这里点击按钮无效
      game && goTo(game);
    } else {
      // ToDo 无默认值这里点击按钮无效
      goWhatsAppChat(url.split(/[^\d]+/).find(s => s.length === 10));
    }
  };
  const viewDetail = (item: PromotionListItem) => {
    globalStore.asyncSetItem('promotion-detail', JSON.stringify(item));
    goTo('PromotionDetail');
  };
  const renderItem = ({item, index}: ListRenderItemInfo<PromotionListItem>) => {
    // 此处先使用固定的图片，后面再根据接口替换
    const content = item.activityContent?.replace(/<[^>]+>/g, '').trim();
    return (
      <View style={[theme.margin.btml]} key={item.id}>
        <Card
          key={index}
          backgroundColor={theme.basicColor.white}
          radius={theme.borderRadiusSize.m}>
          <NativeTouchableOpacity
            // style={{
            //   height: (screenWidth * (content ? 216 : 200)) / 375,
            // }}
            onPress={() => {
              if (item.activityUrl) {
                goPromotion(item.activityUrl, item.activityTitle);
                return;
              }
              if (
                // TODO 这里不知道为什么要过滤空标签
                // (item.activityContent?.replace(/<[^>]+>/g, '').trim() ||
                //   item.activityContent?.startsWith('<img') ||
                //   item.activityContent?.startsWith('<IMG'))
                item.activityContent &&
                item.activityContent.length > 0
              ) {
                viewDetail(item);
                return;
              }
            }}>
            <Card.Image
              width={(screenWidth * 351) / 375}
              height={
                // item.activityType === 'signin' ? signImgHeight : itemImgHeight
                (screenWidth * 120) / 375
              }
              imageUrl={item.activityIcon}
              radius={theme.borderRadiusSize.m}
            />
            {packageId === 3 && (
              <View
                style={[
                  theme.flex.row,
                  theme.flex.centerByCol,
                  {
                    padding: (screenWidth * 12) / 375,
                  },
                ]}>
                <View style={[theme.flex.flex1]}>
                  <Text
                    numberOfLines={1}
                    main
                    calc
                    blod
                    fontSize={theme.paddingSize.xl}>
                    {item.activityTitle}
                  </Text>
                  {content && (
                    <Text
                      calc
                      numberOfLines={3}
                      accent
                      fontSize={theme.paddingSize.l}
                      style={[
                        {
                          marginTop: (screenWidth * 8) / 375,
                        },
                      ]}>
                      {content}
                    </Text>
                  )}
                </View>
                <Button
                  size="large"
                  width={(screenWidth * 96) / designWidth}
                  style={[
                    {
                      marginLeft: (screenWidth * 12) / designWidth,
                    },
                  ]}
                  type="linear-primary"
                  onPress={() =>
                    goPromotion(item.activityUrl, item.activityTitle)
                  }>
                  <Text
                    calc
                    fontSize={theme.fontSize.s}
                    blod
                    color={theme.basicColor.white}>
                    {i18n.t('bets-page.label.viewDetails')}
                  </Text>
                </Button>
              </View>
            )}
          </NativeTouchableOpacity>
        </Card>
      </View>
    );
  };
  return (
    <FadeInView style={[{height: screenHeight}, theme.background.lightGrey]}>
      <DetailNavTitle
        title={i18n.t('promotion.title')}
        hideServer
        onBack={goBack}
      />
      <FlatList
        style={[theme.flex.flex1]}
        data={promotionList}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              refreshPage(false);
            }}
          />
        }
        ListEmptyComponent={
          <View style={[theme.padding.xxl]}>
            <NoData />
          </View>
        }
        onEndReached={refreshNextPage}
        contentContainerStyle={[
          theme.padding.lrl,
          theme.padding.topl,
          listStyle.list,
        ]}
        ListFooterComponent={
          promotionList &&
          promotionList.length > 0 &&
          pageNo.current >= totalPage.current ? (
            <NoMoreData />
          ) : undefined
        }
      />
    </FadeInView>
  );
};

export default Promotion;
